<template>
  <div class="login-wrapper">
    <div class="div-header">
      <div class="row row-header">
        <div class="col-6 logo">
          <img
            alt="Panasonic Logo"
            class="nav-logo"
            contain
            src="@/assets/logo-KTC.png"
          />
        </div>
      </div>
    </div>
    <div class="row row-title-page">
      <v-col cols="12">
        <div class="app-title">
          <div class="top"></div>
          <div class="middle">Sports Smart Lighting Solution</div>
        </div>
      </v-col>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center justify-content-center card-login">
          <div class="col-md-8 col-lg-6 col-xl-5 col-card-login">
            <div class="card card-login">
              <div class="card-body shadow p-4">
                <v-form
                  ref="loginForm"
                  @submit.prevent="submit"
                  v-model="valid"
                  autocomplete="off"
                >
                  <v-row no-gutters class="pt-2">
                    <v-col cols="3" class="login-label">Username</v-col>
                    <v-col cols="9">
                      <v-text-field
                        label=""
                        class="login-input"
                        v-model="userName"
                        required
                        outlined
                        filled
                        single-line
                        prepend-inner-icon="fas fa-user"
                        placeholder="Username"
                        :rules="userValidation"
                        autocomplete="off"
                      ></v-text-field>
                      <div
                        class="error-message error-message-pink"
                        v-if="this.errUserCount > 0"
                      >
                        {{ userValidationMsg }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="3" class="login-label">
                      <span>Password</span>
                    </v-col>
                    <v-col cols="9">
                      <v-text-field
                        autocomplete="off"
                        label=""
                        class="login-input"
                        v-model="password"
                        min="8"
                        required
                        outlined
                        filled
                        single-line
                        placeholder="Password"
                        :type="showPass ? 'text' : 'password'"
                        prepend-inner-icon="fas fa-lock"
                        :rules="passwordValidation"
                      >
                      </v-text-field>
                      <div
                        class="error-message error-message-pink"
                        v-if="this.errPassCount > 0"
                      >
                        {{ passValidationMsg }}
                      </div>
                      <div
                        id="error-text"
                        class="error-message error-message-pink"
                        v-if="errorText !== '' && !passValidationMsg && !userValidationMsg"
                      >
                        {{ errorText }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" class="forgot-link text-right">
                      <router-link to="/forgot-password" class="color-fg">
                        Forgot Password
                      </router-link>
                    </v-col>
                  </v-row>
                  <div class="row center">
                    <button
                      type="submit"
                      :class="{
                        'no-cursor': !valid,
                      }"
                      class="button-login"
                    >
                      Login
                    </button>
                  </div>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-title">
      <span>{{ ktc_version }}</span>
    </div>
    <div class="footer-copyright">
      <h5 style="color: #b5b5b5">
        <span style="color: #ffffff"
          ><span style="color: #ffffff; font-weight: bold">Powered by </span
          >Panasonic</span
        >
      </h5>
    </div>
  </div>
</template>

<style src="../assets/css/login.css"></style>

<script>
import { auth } from "../services/";
export default {
  data() {
    return {
      ktc_version: "Sports Smart Lighting Solution Ver.2.0.1 ©2023",
      showPass: false,
      userName: "",
      password: "",
      errorText: "",
      warningMsg: "",
      remainingLockMsg: "",
      lockMsg: "",
      userValidationMsg: "",
      passValidationMsg: "",
      errUserCount: 0,
      errPassCount: 0,
      timeout: 2000,
      valid: false,
      countAttempt: 5,
      t: null,
    };
  },
  async created() {
    this.ktc_version = await this.getVersion();
  },
  computed: {
    userValidation() {
      return [
        (v) => {
          if (this.errUserCount == 0) {
            this.errUserCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.userValidationMsg = "Username is required";
            return false;
          } else {
            this.userValidationMsg = "";
            return true;
          }
        },
      ];
    },
    passwordValidation() {
      return [
        (v) => {
          if (this.errPassCount == 0) {
            this.errPassCount++;
            return false;
          }
          if (!v) {
            this.valid = false;
            this.passValidationMsg = "Password is required";
            return false;
          } else {
            this.passValidationMsg = "";
            return true;
          }
        },
      ];
    },
  },
  methods: {
    validUserName() {
      if (!this.userName) {
        this.valid = false;
        this.userValidationMsg = "Username is required";
        return false;
      }
      this.userValidationMsg = "";
      return true;
    },
    validPassword() {
      if (!this.password) {
        this.valid = false;
        this.passValidationMsg = "Password is required";
        return false;
      }
      this.passValidationMsg = "";
      return true;
    },
    submit() {
      clearTimeout(this.t);
      this.errorText = "";
      if (this.validUserName() && this.validPassword()) {
        const username = this.userName;
        const password = this.password;
        auth
          .login({ username, password })
          .then((res) => {
            if (res.status == 200 && res.data.success) {
              if (res?.data?.data?.token) {
                this.$store
                  .dispatch("login", res.data.data)
                  .then(() => (window.location.href = "/"));
              } else {
                this.errorText = "The username or password you entered is invalid.";
                  if (res?.data?.data?.message) {
                    this.errorText = res?.data?.data?.message
                  }
                this.snackbar = true;
              }
            } else {
              throw res;
            }
          })
          .catch((err) => {
            this.errorText = "The username or password you entered is invalid.";
            if (err?.response?.data?.message) {
              this.countAttempt = 0;
              this.errorText = err?.response?.data?.message;
            }
          });
      }
    },
  },
};
</script>
